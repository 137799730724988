import { Utils } from '../wishlistView/Utils.js';

class MiniWishlist {
    constructor(element, toggles = { toggleActive: () => false }) {
        this._toggles = toggles;
        this.element = element;
        this.emptyBadgeClass = 'wl_mini__badge--empty';
        this.storage = new window.o_global.Storage(window.localStorage);
        this.amountStorageKey = 'wl_miniWishlistAmount';
        this.utils = new Utils();
        if (this.element) {
            this.amountBadge = this.element.querySelector('.wl_js_mini_amount');
        } else {
            this.amountBadge = null;
        }
        this.amountBadgePresent = this.amountBadge !== null;
    }

    init() {
        if (this.amountBadgePresent) {
            window.document.addEventListener('wishlist.changed', e => this.onWishlistChanged(e));

            /*                                    */
            window.addEventListener('storage', e => this.onStorageChange(e));

            /*                       */
            window.setTimeout(() => this.onPageLoad(), 10);
        }
        return this;
    }

    onPageLoad() {
        const savedAmount = this.getAmountFromStorage();
        if (savedAmount !== undefined) {
            this.renderAmount(savedAmount);
            this.trackAmountOnPageImpression(savedAmount);
        } else {
            this.refreshAmount();
        }
        return this;
    }

    onWishlistChanged(event) {
        if (event && event.detail && event.detail.newAmount) {
            const newAmount = event.detail.newAmount;
            if (this.amountBadgePresent) {
                this.renderAmount(newAmount);
            }
            this.saveToStorage(newAmount);
            return Promise.resolve();
        }
        return this.refreshAmount();
    }

    onStorageChange(storageEvent) {
        if (storageEvent.key === this.amountStorageKey) {
            const amount = this.getAmountFromStorage(storageEvent.newValue);
            if (amount !== undefined) {
                this.renderAmount(amount);
            }
        }
    }

    getAmountFromStorage(currentValue) {
        let storageData;
        if (currentValue !== undefined) {
            storageData = currentValue;
        } else {
            storageData = this.storage.getItem(this.amountStorageKey);
        }
        return this.parseStorageData(storageData);
    }

    parseStorageData(dataString) {
        try {
            const data = JSON.parse(dataString);
            if (data && data.expire > new Date().getTime() && data.visitorId === this.getVisitorId() && data.loggedIn === this.utils.isLoggedIn()) {
                return data.amount;
            }
            return undefined;
        } catch (e) {
            return undefined;
        }
    }

    saveToStorage(amount) {
        const data = {
            amount: amount,
            visitorId: this.getVisitorId(),
            expire: new Date().getTime() + 300000,
            loggedIn: this.utils.isLoggedIn()
        };
        this.storage.setItem(this.amountStorageKey, JSON.stringify(data));
        return amount;
    }

    refreshAmount() {
        return this.loadAmount().then(loadedAmount => {
            this.saveToStorage(loadedAmount);
            this.renderAmount(loadedAmount);
            this.trackAmountOnPageImpression(loadedAmount);
        });
    }

    loadAmount() {
        const amountUrl = this.amountBadge.getAttribute('data-amount-url');
        return window.o_util.ajax.getJSON(amountUrl).then(xhr => xhr.responseJSON.amount);
    }

    toggleBadge(showBadge) {
        if (this.amountBadgePresent) {
            this.amountBadge.classList.toggle(this.emptyBadgeClass, !showBadge);
        }
    }

    renderAmount(amount) {
        this.amountBadge.innerHTML = amount;
        this.toggleBadge(amount > 0);
    }

    getVisitorId() {
        return window.o_util.cookie.get('visitorId');
    }

    trackAmountOnPageImpression(amount) {
        window.o_global.eventQBus.emit('tracking.bct.addToPageImpression', {
            wishlist_WishlistItems: amount
        });
    }
}

export { MiniWishlist };
